
// import React from 'react'
// const context = React.createContext('hello');
// export default context;

import React, { useState,createContext } from "react";


// type LimitContextType = {
//     limit:Number,
//     setLimit: React.Dispatch<React.SetStateAction<Number>>
// }

type LimitContextProviderProps ={
    children : React.ReactNode
}

export const LimitContext = createContext< any >(null)

export const LimitContextProvider = ({children}: LimitContextProviderProps)=>{
    const [limit,setLimit] = useState<Number>(10)
    const [start,setStart] = useState<Number>(0)
    const [page,setPage] = useState<Number>(0)
    const [fileterClusterId,setFilterClusterId] = useState<string>()
    return <LimitContext.Provider value={{ limit ,setLimit ,start , setStart,page,setPage,fileterClusterId,setFilterClusterId }}>{children}</LimitContext.Provider>
}
