import { gql } from '@apollo/client';

export const GET_MARKET_READINESS_ASSESSMENTS = gql`
	query marketReadinessAssessments(
		$sort: String
		$limit: Int
		$start: Int
		$where: JSON
		$publicationState: PublicationState
	) {
		marketReadinessAssessments(
			sort: $sort
			limit: $limit
			start: $start
			where: $where
			publicationState: $publicationState
		) {
			id
			completed
			annual_turnover
			registered_seller
			active_participation_year
			turnover_percentage
			global_supplier
			global_supply_year
			own_brand
			result
			business_entity {
				id
				name
			}
			users_permissions_user {
				id
				email
			}
		}
	}
`;
