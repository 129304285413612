import React, { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import jwtDecode from 'jwt-decode';
import type { User } from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'axios';
import {
	LOGIN_API,
	USER_ME_API,
	VERIFY_EMAIL_AND_REGISTER,
} from 'src/utils/endpoints.util';
import { useHistory } from 'react-router';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { GET_USER_PROFILE } from 'src/graphql/userProfile/query';
import { GET_MARKET_READINESS_ASSESSMENTS } from 'src/graphql/marketRedinessSurvey/query';
import { GET_BUSINESS_ENTITY_PRODUCT } from 'src/graphql/businessEntityProduct/query';
import {ENTERPRISE_MASTER } from 'src/utils/endpoints.util';

interface AuthState {
	isInitialised: boolean;
	isAuthenticated: boolean;
	isSurveyCompleted: boolean;
	user: User | null;
}

interface AuthContextValue extends AuthState {
	method: 'JWT';
	login: (email: string, password: string,captcha_text :string,captcha_id:string) => Promise<void>;
	logout: () => void;
	verifyEmailAndRegister: (
		email: string,
		name: string,
		password: string,
	) => Promise<void>;
	setUser: (user) => Promise<void>;
	setSession: (accessToken: string | null) => void;
}

interface AuthProviderProps {
	children: ReactNode;
}

type InitialiseAction = {
	type: 'INITIALISE';
	payload: {
		isAuthenticated: boolean;
		isSurveyCompleted?: boolean;
		user: User | null;
	};
};

type LoginAction = {
	type: 'LOGIN';
	payload: {
		user: User;
	};
};

type LogoutAction = {
	type: 'LOGOUT';
};

type RegisterAction = {
	type: 'REGISTER';
	payload: {
		user: User;
	};
};

type Action = InitialiseAction | LoginAction | LogoutAction | RegisterAction;

const initialAuthState: AuthState = {
	isAuthenticated: false,
	isSurveyCompleted: false,
	isInitialised: false,
	user: null,
};

const isValidToken = (accessToken: string): boolean => {
	if (!accessToken) {
		return false;
	}

	const decoded: any = jwtDecode(accessToken);
	const currentTime = Date.now() / 1000;

	return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null): void => {
	if (accessToken) {
		localStorage.setItem('accessToken', accessToken);
		axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
	} else {
		localStorage.removeItem('accessToken');
		delete axios.defaults.headers.common.Authorization;
	}
};

const reducer = (state: AuthState, action: Action): AuthState => {
	switch (action.type) {
		case 'INITIALISE': {
			const { isAuthenticated, user } = action.payload;
			let isSurveyCompleted =
				user?.user_profile?.mobile_number_verified &&
				(user?.user_profile?.business_entity
					? 'key' in user?.user_profile?.business_entity
					: false) &&
				user?.market_readiness?.completed;
			return {
				...state,
				isAuthenticated,
				isSurveyCompleted,
				isInitialised: true,
				user,
			};
		}
		case 'LOGIN': {
			const { user } = action.payload;
			let isSurveyCompleted =
				user?.user_profile?.mobile_number_verified &&
				(user?.user_profile?.business_entity
					? 'key' in user?.user_profile?.business_entity
					: false) &&
				user?.market_readiness?.completed;
			return {
				...state,
				isAuthenticated: true,
				isSurveyCompleted,
				user,
			};
		}
		case 'LOGOUT': {
			return {
				...state,
				isAuthenticated: false,
				user: null,
			};
		}
		case 'REGISTER': {
			const { user } = action.payload;
			let isSurveyCompleted =
				user?.user_profile?.mobile_number_verified &&
				(typeof user?.user_profile?.business_entity === 'object'
					? 'key' in user?.user_profile?.business_entity
					: false) &&
				user?.market_readiness?.completed;
			return {
				...state,
				isAuthenticated: true,
				isSurveyCompleted,
				user,
			};
		}
		default: {
			return { ...state };
		}
	}
};

const AuthContext = createContext<AuthContextValue>({
	...initialAuthState,
	method: 'JWT',
	login: () => Promise.resolve(),
	logout: () => {},
	verifyEmailAndRegister: () => Promise.resolve(),
	setUser: () => Promise.resolve(),
	setSession: () => {},
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialAuthState);
	const history = useHistory();
	const { user } = state;
	const apolloClient = useApolloClient();
	// const [ getMarketReadinessAssessment ]= useLazyQuery(GET_MARKET_READINESS_ASSESSMENTS,{ onCompleted:(data) => {
	//   if(!data.marketReadinessAssessments[0])
	//   return

	//   dispatch({
	//     type: 'INITIALISE',
	//     payload: {
	//       isAuthenticated: true,
	//       user : {...user,market_readiness: data.marketReadinessAssessments[0] }
	//     }
	//   });
	// }})

	const getUserProfileRequest = async (userProfileId: any) =>
		await apolloClient.query({
			query: GET_USER_PROFILE,
			variables: { where: { id: userProfileId } },
		});

	const getMarketReadinessAssessmentRequest = async (business_entity: string) =>
		await apolloClient.query({
			query: GET_MARKET_READINESS_ASSESSMENTS,
			variables: { where: { business_entity: parseInt(business_entity) } },
		});

	const getBusinessEntityProducts = async (business_entity: string) =>
		await apolloClient.query({
			query: GET_BUSINESS_ENTITY_PRODUCT,
			variables: { where: { business_entity: parseInt(business_entity) } },
		});

	const [getUserProfile] = useLazyQuery(GET_USER_PROFILE, {
		onCompleted: async data => {
			if (!data.userProfiles[0]) return;
			let businessEntityProducts: any = [];
			let marketReadinessAssessmentResponse: any = {};

			// if (data.userProfiles[0]?.business_entity) {
				// marketReadinessAssessmentResponse = await getMarketReadinessAssessmentRequest(
				// 	data.userProfiles[0]?.business_entity.id || '',
				// );
				// businessEntityProducts = await getBusinessEntityProducts(
				// 	data.userProfiles[0]?.business_entity.id || '',
				// );
			// }

			let user_profile = data.userProfiles[0];
			let businessEntityProductsArr = businessEntityProducts?.data?.businessEntityProducts?.map(
				({ product }) => product,
			);
			let businessEntity = {
				...user_profile?.business_entity,
				products: businessEntityProductsArr,
			};
			dispatch({
				type: 'INITIALISE',
				payload: {
					isAuthenticated: true,
					user: {
						...user,
						market_readiness:
							marketReadinessAssessmentResponse?.data
								?.marketReadinessAssessments[0] || {},
						user_profile: {
							...user_profile,
							business_entity: user_profile?.business_entity
								? businessEntity
								: null,
						},
					},
				},
			});
		},
	});

	const login = async (email: string, password: string,captcha_text :string,captcha_id:string) => {
		localStorage.removeItem('accessToken');
		const response = await axios.post<{ jwt: string; user: User }>(LOGIN_API, {
			identifier: email,
			password,
			captcha_text,
			captcha_id
		});

		let { jwt, user } = response?.data;
		if (isValidToken(jwt)) setSession(jwt);
		if (user?.user_profile) {
			const enterpriseMaster:any = user?.role?.type == 'enabler' ? await axios.get<any>(ENTERPRISE_MASTER) : [];
			    let masterEnterprise = enterpriseMaster?.data || [] ;
			// getUserProfile({ variables:{ where : { id: user.user_profile?.id}}})
			const userProfileResponse = await getUserProfileRequest(
				user?.user_profile?.id || '',
			);
			user = {
				...user,
				user_profile: userProfileResponse.data.userProfiles[0],
				masterEnterprise: masterEnterprise
			};
			if (user.user_profile?.business_entity) {
				// const marketReadinessAssessmentResponse = await getMarketReadinessAssessmentRequest(
				// 	user.user_profile?.business_entity.id || '',
				// );
				// const businessEntityProducts = await getBusinessEntityProducts(
				// 	user.user_profile?.business_entity.id || '',
				// );
				// let businessEntityProductsArr = businessEntityProducts?.data?.businessEntityProducts?.map(
				// 	({ product }) => product,
				// );

				let businessEntity: any = {
					...user.user_profile?.business_entity,
					products: [],
				};

				user = {
					...user,
					user_profile: {
						...user?.user_profile,
						business_entity: user?.user_profile?.business_entity
							? businessEntity
							: null,
					},
					market_readiness: null,
							masterEnterprise: masterEnterprise
				};
			}
		}

		dispatch({
			type: 'LOGIN',
			payload: {
				user,
			},
		});
	};

	const logout = () => {
		setSession(null);
		dispatch({ type: 'LOGOUT' });
	};

	const verifyEmailAndRegister = async (email: string, otp: string) => {
		const response = await axios.post<{
			jwt: string;
			resetPasswordToken: string;
			user: User;
		}>(VERIFY_EMAIL_AND_REGISTER, {
			email,
			otp,
		});
		const { jwt, resetPasswordToken, user } = response.data;

		if (user?.user_profile?.id)
			getUserProfile({ variables: { where: { id: user?.user_profile?.id } } });

		if (isValidToken(jwt)) setSession(jwt);

		dispatch({
			type: 'REGISTER',
			payload: {
				user,
			},
		});

		history.push(`/password-reset/${resetPasswordToken || ''}`);
	};

	const setUser = async (user: User) => {
		dispatch({
			type: 'INITIALISE',
			payload: {
				isAuthenticated: true,
				user,
			},
		});
	};

	useEffect(() => {
		const initialise = async () => {
			try {
				const accessToken = window.localStorage.getItem('accessToken');

				if (accessToken && isValidToken(accessToken)) {
					setSession(accessToken);
					const response = await axios.get<User>(USER_ME_API);
					const enterpriseMaster:any = await axios.get<any>(ENTERPRISE_MASTER);
			    	let masterEnterprise = enterpriseMaster?.data || [];
					let user: any = response?.data || {};
					let userProfileResponse;

					if (user?.user_profile)
						userProfileResponse = await getUserProfileRequest(
							user?.user_profile || '',
						);
					// user = {...user , user_profile : userProfileResponse.data.userProfiles[0]}

					let businessEntityProducts: any = [];
					let marketReadinessAssessmentResponse: any = {};

					// if (userProfileResponse?.data?.userProfiles[0]?.business_entity) {
					// 	marketReadinessAssessmentResponse = await getMarketReadinessAssessmentRequest(
					// 		userProfileResponse?.data?.userProfiles[0]?.business_entity?.id ||
					// 			'',
					// 	);
					// 	businessEntityProducts = await getBusinessEntityProducts(
					// 		userProfileResponse?.data?.userProfiles[0]?.business_entity?.id ||
					// 			'',
					// 	);
					// }

					let user_profile = userProfileResponse?.data?.userProfiles[0];
					let businessEntityProductsArr = businessEntityProducts?.data?.businessEntityProducts?.map(
						({ product }) => product,
					);
					let businessEntity = {
						...user_profile?.business_entity,
						products: businessEntityProductsArr,
					};

					// getUserProfile({ variables:{ where : { id: user.user_profile}}})

					dispatch({
						type: 'INITIALISE',
						payload: {
							isAuthenticated: true,
							user: {
								...user,
								market_readiness:
									marketReadinessAssessmentResponse?.data
										?.marketReadinessAssessments[0] || {},
								user_profile: {
									...user_profile,
									business_entity: user_profile?.business_entity
										? businessEntity
										: null,
								},
								masterEnterprise: masterEnterprise
							},
						},
					});
				} else {
					dispatch({
						type: 'INITIALISE',
						payload: {
							isAuthenticated: false,
							user: null,
						},
					});
				}
			} catch (err) {
				console.error(err);
				window.localStorage.removeItem('access_token');
				dispatch({
					type: 'INITIALISE',
					payload: {
						isAuthenticated: false,
						user: null,
					},
				});
			}
		};

		initialise();
	}, []);

	if (!state.isInitialised) {
		return <SplashScreen />;
	}

	return (
		<AuthContext.Provider
			value={{
				...state,
				method: 'JWT',
				login,
				logout,
				verifyEmailAndRegister,
				setUser,
				setSession,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
