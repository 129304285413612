export const APP_VERSION = '1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
	LIGHT: 'LIGHT',
	// ONE_DARK: 'ONE_DARK',
	// UNICORN: 'UNICORN',
	DARK: 'DARK',
	NATURE: 'NATURE',
};

export function hasWhiteSpace(s) {
	return /\s/g.test(s);
}

export function hasAlphabets(s) {
	var regExp = /[a-zA-Z]/g;
	return regExp.test(s);
}

export const excludeProductId = ['Others', 'DELETE Others']
