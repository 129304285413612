import React from 'react';
import type { FC } from 'react';
import logo from '../assets/images/logo.png'
import footer from '../assets/images/footer.png'
import header from '../assets/images/header.png'
interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {
  return (
    <img
      alt="Logo"
      src={logo}
      style={{   width:  "160px",
        height: "65px"}}
      {...props}
    />
  );
}

export default Logo;
