import { gql } from '@apollo/client';

export const GET_BUSINESS_ENTITY_PRODUCT = gql`
	query businessEntityProducts(
		$sort: String
		$limit: Int
		$start: Int
		$where: JSON
		$publicationState: PublicationState
	) {
		businessEntityProducts(
			sort: $sort
			limit: $limit
			start: $start
			where: $where
			publicationState: $publicationState
		) {
			id
			business_entity {
				id
				name
			}
			product {
				id
				name
				product_category {
					id
					name
				}
			}
		}
	}
`;
