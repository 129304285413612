import React, { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import type { Theme } from 'src/theme';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { Offline, Online } from "react-detect-offline";
import { useSnackbar } from 'notistack';
import { Alert } from '@material-ui/lab';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import { AlignCenter } from 'react-feather';
interface DashboardLayoutProps {
  children?: ReactNode
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.default
    // background: 'red'
  },
  offline: {
    // background: "red",
    // color: "white",
     fontSize: "20px",
     textAlign: "center"
  },
}));

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const [ isOnline, setisOnline ] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

	// useEffect(() => {
	// 	if (isOnline) {
	// 		setisOnline(false);
	// 		enqueueSnackbar('welcome back !', {
	// 			variant: 'success',
	// 		});
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [isOnline]);

		useEffect(() => {
		  const interval = setInterval(() => {
			  setisOnline(navigator.onLine)
		  }, 1000);
		  if(interval && isOnline){
			enqueueSnackbar('welcome back !', {
				variant: 'success',
			});
		  }
		  return () => clearInterval(interval);
		}, [isOnline]);

	return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {/* <Online
              onChange={online => {
                if (online) setisOnline(online);
              }}
            ></Online> */}
            {/* <Offline> */}
              {!isOnline && (<Box alignSelf="center">
                <Alert severity="warning" className={classes.offline}>
                  {' '}
                  <WifiOffIcon fontSize="default" /> It Seems You Are Offline :({' '}
                </Alert>
              </Box>)}
            {/* </Offline> */}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
	children: PropTypes.node,
};

export default DashboardLayout;
