import { gql } from '@apollo/client';

export const GET_USER_NOTIFICATIONS = gql`
	query userNotifications(
		$sort: String
		$limit: Int
		$start: Int
		$where: JSON
		$publicationState: PublicationState
	) {
		userNotifications(
			sort: $sort
			limit: $limit
			start: $start
			where: $where
			publicationState: $publicationState
		) {
			id
			interested
			view_count
			delivered
			delivery_report
			published_at
			users_permissions_user {
				id
				email
			}
			notification {
				id
				title
				description
				type
				medium
				body
				redirect_link
				expired_at
				published_at
				is_publish
				icon {
					id
					url
				}
			}
		}
	}
`;
