import { gql } from '@apollo/client';
const { REACT_APP_BASEURL, REACT_APP_BASEURL_L3 } = process.env;

export const LOGIN_API = `${REACT_APP_BASEURL}auth/local`;
export const USER_ME_API = `${REACT_APP_BASEURL}users/me`;
export const SEND_EMAIL_OTP = `${REACT_APP_BASEURL}auth/send-email-otp`;
export const VERIFY_EMAIL_AND_REGISTER = `${REACT_APP_BASEURL}auth/verify-email-otp`;
export const PASSWORD_RESET = `${REACT_APP_BASEURL}auth/reset-password`;
export const SEND_MOBILE_OTP = `${REACT_APP_BASEURL}user-profiles/send-otp`;
export const VERIFY_MOBILE_OTP = `${REACT_APP_BASEURL}user-profiles/verify-otp`;
export const RETRY_MOBILE_OTP = `${REACT_APP_BASEURL}user-profiles/retry-otp`;
export const FILE_UPLOAD = `${REACT_APP_BASEURL}upload`;
export const FILE_UPLOAD_MORPH = `${REACT_APP_BASEURL}upload/file-morph`;
export const GOOGLE_SIGN_IN = `${REACT_APP_BASEURL}auth/google/callback`;
export const NOTIFICATION_SEND = `${REACT_APP_BASEURL}user-notifications/send`;
export const ADD_USERS_LIVE_SESSION = `${REACT_APP_BASEURL}live-sessions/add-users`;
export const PUBLISH_LIVE_SESSION = `${REACT_APP_BASEURL}live-sessions/publish`;

// Apis related to Leads Bulk Upload Module.
export const LEADS_BULK_UPLOAD = `${REACT_APP_BASEURL}leads/bulkUpload`;
export const PLAYABOLO_BULK_UPLOAD = `${REACT_APP_BASEURL}playablo-data/bulkupload`;
export const LEAD_CLUSTER_EXPORT = `${REACT_APP_BASEURL}leads/getAllLeadsCsv`;
export const LEADS_BULK_UPLOAD_LOGS = `${REACT_APP_BASEURL}lead-bulk-imports/get-data-leadlog`;
export const FKB2C_BULK_UPLOAD_LOGS = `${REACT_APP_BASEURL}lead-bulk-imports/get-data-fklog`;
export const LEADS_BULK_DELETE = `${REACT_APP_BASEURL}lead-bulk-imports/bulk-delete`;
export const LEAD_PLAYABLO_DATA_UPLOAD = `${REACT_APP_BASEURL}leads/update-playabloId`;
export const REVERT_BULK_UPLOAD = `${REACT_APP_BASEURL}lead-bulk-imports`;
export const DOWNLOAD_REPORT_LOG = `${REACT_APP_BASEURL}lead-bulk-imports/get-download-csv`;

export const FKB2C_BULK_UPLOAD = `${REACT_APP_BASEURL}lead-bulk-imports/upload-fk-business-performance`;

export const CREATE_USER = `${REACT_APP_BASEURL}cluster-managers/mapping`;
export const FETCH_USER = `${REACT_APP_BASEURL}cluster-managers/`;

export const LEGACY_DATA = `${REACT_APP_BASEURL}legacy-data`;
export const BUSINESS_ENTITY_PRODUCT = `${REACT_APP_BASEURL}business-entity-products`;
export const LEAD_ASSIGNMENT = `${REACT_APP_BASEURL}lead-assignments`;
export const LEAD_RE_ASSIGNMENT = `${REACT_APP_BASEURL}leads/reassign-leads`;
export const LEAD_REMINDER_UPDATE_STATUS = `${REACT_APP_BASEURL}reminders/update-status`;
export const READY_TO_CALL = `${REACT_APP_BASEURL}lead-assignments/ready-to-call`;

export const LEADS_COUNT = `${REACT_APP_BASEURL}leads/count`;
export const LEAD_RE_ENGAGING_CLOSE = `${REACT_APP_BASEURL}leads/close-reengage`;
export const LEAD_ASSIGNMENT_FORM_ACTION = `${REACT_APP_BASEURL}lead-assignments/form-action`; // telecaller form action
export const LEAD_ASSIGNMENT_ENABLER_FORM_ACTION = `${REACT_APP_BASEURL}lead-assignments/enabler-form-action`;
export const LEAD_ASSIGNMENT_FLIPKART_FORM_ACTION = `${REACT_APP_BASEURL}lead-assignments/flipkart-form-action`;
export const GET_ROLE_DETAILS_BY_ROLE_TYPE = `${REACT_APP_BASEURL}user-profiles/roleFilter`;
export const LEAD_PRODUCTS = `${REACT_APP_BASEURL}lead-products-map`;
export const UNIQUE_LEAD_CHECK = `${REACT_APP_BASEURL}leads/unique`;
export const LEAD_CREAT_CHECK = `${REACT_APP_BASEURL}leads/check-learner-exist`;
// Apis Fro Assessments
export const ASSESSMENT_USER_MAPPINGS = `${REACT_APP_BASEURL}assesment-user-mappings`;
export const ASSESSMENT_ATTEMPT_QUESTION = `${REACT_APP_BASEURL}question`;
export const ASSESSMENT_ATTEMPT_ANSWER = `${REACT_APP_BASEURL}assessment-attempt-answers`;
export const ASSESSMENT_RESULT = `${REACT_APP_BASEURL}assesment-result`;
export const ASSESSMENT_STATUS = `${REACT_APP_BASEURL}user-profiles/assesment-status`;

export const MSME_CONNECT_LOGIN = `${REACT_APP_BASEURL}user-profiles/msmeconnect`;
export const GET_EVENT_MESSAGE_FIELDS = `${REACT_APP_BASEURL}event-message-templates/trigger-list`;
export const CREATE_LEAD_ACTIVITY_LOG_AND_REMINDER = `${REACT_APP_BASEURL}lead-activity-logs`;

export const GET_PLAYABLO_DATA_MATERIALIZED_VIEW = `${REACT_APP_BASEURL}playabloDataMatView`;
export const GET_FK_ADMIN_REPORT = `${REACT_APP_BASEURL}lead-assignments/fkadminreport`;
export const GET_OPERATION_HEAD_REPORT = `${REACT_APP_BASEURL}lead-assignments/operationheadreport`;
export const GET_FK_FILTER_LEAD = `${REACT_APP_BASEURL}lead-assignments/filters-api-for-graphql`;
export const GET_FK_CSV = `${REACT_APP_BASEURL}leads/getFlipkartData`;
export const FLIPKART_BULK_UPLOAD = `${REACT_APP_BASEURL}leads/flipkartCsvUpload`;
export const GET_FKADMIN_DATA_CSV = `${REACT_APP_BASEURL}lead-assignments/getFkDataCsv`;
export const GET_MSME_USER_LIST = `${REACT_APP_BASEURL}lead-assignments/msme-user-list`;
// export const GET_PLAYABLO_DATA_MATERIALIZED_VIEW = gql`
// query playabloData(
//     $sort: String
//     $limit: Int
//     $start: Int
//     $where: JSON
//     $publicationState: PublicationState
// ) {
//     gdsmvu_course_data_2_playablo_data(
//         sort: $sort
//         limit: $limit
//         start: $start
//         where: $where
//         publicationState: $publicationState
//     ) {
//         id
// created_at
// updated_at
// playabolo_id

//     }
// }
// `;

//trigger
export const TRIGGERS = `${REACT_APP_BASEURL}triggers`;
//notification template
export const NOTIFICATION_TEMPLATE_SEND = `${REACT_APP_BASEURL}`;

export const GET_ENTERPRISE_PROFILE = `${REACT_APP_BASEURL}enterprise-profile-leads/get-enterprise-data-byweb`;
export const CREATE_ENTERPRISE_PROFILE = `${REACT_APP_BASEURL}enterprise-profile-leads/create-enterprise-byweb`;
export const ENTERPRISE_MASTER = `${REACT_APP_BASEURL}enterprise-profile-masters`;
// The l3 urls format was l3/v1/example-endpoint deleted l3/v1 part
//TODO add them back when demo completed
// ===========>     Use this code for demo environment       <=======================
// export const MSME_ASSESMENT = `${REACT_APP_BASEURL_L3}msme-assessment`;
// export const SLOTS = `${REACT_APP_BASEURL_L3}slots`;
// export const PERSONALIZED_ADVISORY = `${REACT_APP_BASEURL_L3}personalized-advisory`;
// export const MENTOR_FEEDBACK = `${REACT_APP_BASEURL_L3}mentor-feedback`;
// export const MSME_SESSION_DETAILS = `${REACT_APP_BASEURL_L3}msme-session-details`;
// export const GET_REALLOCATION_DATA = `${REACT_APP_BASEURL_L3}getReallocationData`;
// export const MENTOR_AVAILABLE = `${REACT_APP_BASEURL_L3}mentor-available`;
// export const DISQUALIFY_MENTEE = `${REACT_APP_BASEURL_L3}disqualify-mentee`;
// export const REALLOCATE_MENTOR = `${REACT_APP_BASEURL_L3}reallocate-mentor`;
// export const REALLOCATE_MENTOR_DIRECTLY = `${REACT_APP_BASEURL_L3}reallocate-mentor-directly`;
// export const RECCOMMENT_NEXT_SESSION = `${REACT_APP_BASEURL_L3}recommend-next-session`;
// export const MSME_DETAILS_MENTOR = `${REACT_APP_BASEURL_L3}msme-details-mentor`;
// export const MENTEE_CHANGE_REQUEST = `${REACT_APP_BASEURL_L3}mentee-change-request`;
// export const CONTENT_MASTER = `${REACT_APP_BASEURL_L3}content-master`;

// ===========>     Use this code for stg environment       <=======================
export const MSME_ASSESMENT = `${REACT_APP_BASEURL}l3/v1/msme-assessment`;
export const SLOTS = `${REACT_APP_BASEURL}l3/v1/slots`;
export const PERSONALIZED_ADVISORY = `${REACT_APP_BASEURL}l3/v1/personalized-advisory`;
export const MENTOR_FEEDBACK = `${REACT_APP_BASEURL}l3/v1/mentor-feedback`;
export const MSME_SESSION_DETAILS = `${REACT_APP_BASEURL}l3/v1/msme-session-details`;
export const GET_REALLOCATION_DATA = `${REACT_APP_BASEURL}l3/v1/getReallocationData`;
export const MENTOR_AVAILABLE = `${REACT_APP_BASEURL}l3/v1/mentor-available`;
export const DISQUALIFY_MENTEE = `${REACT_APP_BASEURL}l3/v1/disqualify-mentee`;
export const REALLOCATE_MENTOR = `${REACT_APP_BASEURL}l3/v1/reallocate-mentor`;
export const REALLOCATE_MENTOR_DIRECTLY = `${REACT_APP_BASEURL}l3/v1/reallocate-mentor-directly`;
export const RECCOMMENT_NEXT_SESSION = `${REACT_APP_BASEURL}l3/v1/recommend-next-session`;
export const MSME_DETAILS_MENTOR = `${REACT_APP_BASEURL}l3/v1/msme-details-mentor`;
export const MENTEE_CHANGE_REQUEST = `${REACT_APP_BASEURL}l3/v1/mentee-change-request`;
export const CONTENT_MASTER = `${REACT_APP_BASEURL}l3/v1/content-master`;

export const DYNAMIC_REPORTS = `${REACT_APP_BASEURL}reports`;
