/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import { useQuery, useLazyQuery } from '@apollo/client';
import {
	Avatar,
	Box,
	Divider,
	Drawer,
	Hidden,
	Link,
	List,
	ListSubheader,
	makeStyles,
	Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
	Bell as BellIcon,
	Home as HomeIcon,
	Layers as LayersIcon,
	Map as MapIcon,
	Users as UsersIcon,
	Video as VideoIcon,
	PieChart as PieChartIcon,
	Activity as ActivityIcon,
	UserPlus as UserPlusIcon,
	Clock as ClockIcon,
	Database as DatabaseIcon,
	BookOpen as BookOpenIcon,
	Download as DownloadIcon,
} from 'react-feather';
import {
	StreetviewOutlined,
	TimelineOutlined,
	PeopleOutline,
	PersonAdd,
	PeopleAlt,
} from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import Logo from 'src/components/Logo';
import { GET_USER_CLUSTERS } from 'src/graphql/clusterUser/query';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import type { FC, ReactNode } from 'react';
import { GET_CLUSTERS } from 'src/graphql/cluster/query';
import { GET_PIPELINE_WITH_STAGES } from 'src/graphql/pipeline/query';
interface NavBarProps {
	onMobileClose: () => void;
	openMobile: boolean;
}

interface Item {
	href?: string;
	icon?: ReactNode;
	info?: ReactNode;
	items?: Item[];
	title: string;
}

interface Section {
	items: Item[];
	subheader: string;
}

const cmSections: Section[] = [
	{
		subheader: 'Pipeline',
		items: [
			{
				title: 'Leads',
				icon: LayersIcon,
				href: '/app/pipeline/leads',
				items: [
					{
						title: 'All',
						href: '/app/pipeline/leads',
					},
				],
			},
			{
				title: 'Bulk Upload Logs',
				items: [
					{
						title: 'Lead Logs',
						href: '/app/pipeline/leads/bulkUpload_logs',
					},
					{
						title: 'FKB2C Logs',
						href: '/app/pipeline/leads/FKB2C_Logs',
					},
				],
			},

			{
				title: 'Reports',
				icon: DatabaseIcon,
				href: '/app/pipeline/lead_assignments',
				items: [
					{
						title: 'Assignments',
						icon: UserPlusIcon,
						href: '/app/pipeline/lead_assignments',
					},
					{
						title: 'Logs',
						icon: ActivityIcon,
						href: '/app/pipeline/logs',
					},
					{
						title: 'Reminders',
						icon: ClockIcon,
						href: '/app/pipeline/reminders',
					},
					{
						title: 'Leads',
						icon: UsersIcon,
						href: '/app/pipeline/lead',
					},
				],
			},
			{
				title: 'Registered MSMEs',
				icon: UsersIcon,
				href: '/app/registered-msme/users/partial',
			},
		],
	},
	{
		subheader: 'Management',
		items: [
			// {
			// 	title: 'Triggers',
			// 	icon: UsersIcon,
			// 	href: '/app/management/triggers',
			// 	items: [
			// 		{
			// 			title: 'List Triggers',
			// 			href: '/app/management/triggers',
			// 		},
			// 	],
			// },
			{
				title: 'Users',
				icon: UsersIcon,
				href: '/app/management/users',
				items: [
					{
						title: 'List Users',
						href: '/app/management/users',
					},
				],
			},
			{
				title: 'Notifications',
				icon: BellIcon,
				href: '/app/management/notifications',
				items: [
					{
						title: 'List Notifications',
						href: '/app/management/notifications',
					},
					// {
					// 	title: 'Notification Template',
					// 	href: '/app/management/notifications/template',
					// },
				],
			},
			{
				title: 'Live Sessions',
				icon: VideoIcon,
				href: '/app/management/live-sessions',
				items: [
					{
						title: 'List Sessions',
						href: '/app/management/live-sessions/session',
					},
					{
						title: 'List Webinar',
						href: '/app/management/live-sessions/webinar',
					},
				],
			},
			{
				title: 'Clusters',
				icon: MapIcon,
				href: '/app/management/clusters',
				items: [
					{
						title: 'List Clusters',
						href: '/app/management/clusters',
					},
				],
			},
			{
				title: 'Playablo Data',
				icon: BookOpenIcon,
				href: '/app/management/playablodata',
				items: [
					{
						title: 'List Playablo Data',
						href: '/app/management/playablodata',
					},
				],
			},
		],
	},
];
const adminSections: Section[] = [
	// {
	// 	subheader: "Reports",
	// 	items: [
	// 		{
	// 			title: "Dashboard",
	// 			icon: PieChartIcon,
	// 			href: "/app/reports/dashboard"
	// 		},
	// 		{
	// 			title: "Dashboard Alternative",
	// 			icon: BarChartIcon,
	// 			href: "/app/reports/dashboard-alternative"
	// 		}
	// 	]
	// },
	{
		subheader: 'Pipeline',
		items: [
			{
				title: 'Leads',
				icon: LayersIcon,
				href: '/app/pipeline/leads',
				items: [
					{
						title: 'All',
						href: '/app/pipeline/leads',
					},
				],
			},
			{
				title: 'Personalized Advisory',
				href: '/app/personalized-advisory/1',
				items: [
					{
						title: 'Reallocation',
						icon: PersonAdd,
						items: [
							{
								title: 'First Allocation',
								href: '/app/personalized-advisory/1',
							},
							{
								title: 'Mentee System',
								href: '/app/personalized-advisory/2',
							},
							{
								title: 'Mentor Manual',
								href: '/app/personalized-advisory/3',
							},
						],
					},
					{
						title: 'Post Session',
						icon: PeopleAlt,
						items: [
							{
								title: 'Post Session',
								href: '/app/personalized-advisory/postSession',
							},
							{
								title: 'Attendance No',
								href: '/app/personalized-advisory/attendanceLead',
							},
							{
								title: 'Feedback Pending',
								href: '/app/personalized-advisory/feedbackLead',
							},
						],
					},
				],
			},
			{
				title: 'Bulk Upload Logs',
				items: [
					{
						title: 'Lead Logs',
						href: '/app/pipeline/leads/bulkUpload_logs',
					},
					{
						title: 'FKB2C Logs',
						href: '/app/pipeline/leads/FKB2C_Logs',
					},
				],
			},
			{
				title: 'Reports',
				icon: DatabaseIcon,
				href: '/app/pipeline/lead_assignments',
				items: [
					{
						title: 'Assignments',
						icon: UserPlusIcon,
						href: '/app/pipeline/lead_assignments',
					},
					{
						title: 'Logs',
						icon: ActivityIcon,
						href: '/app/pipeline/logs',
					},
					{
						title: 'Reminders',
						icon: ClockIcon,
						href: '/app/pipeline/reminders',
					},
					{
						title: 'Download Reports',
						icon: DownloadIcon,
						href: '/app/pipeline/downloadreport',
					},
				],
			},
			{
				title: 'Registered MSMEs',
				icon: UsersIcon,
				href: '/app/registered-msme/users/partial',
			},
		],
	},
	{
		subheader: 'Management',
		items: [
			{
				title: 'Users',
				icon: UsersIcon,
				href: '/app/management/users',
				items: [
					{
						title: 'List Users',
						href: '/app/management/users',
					},
				],
			},
			{
				title: 'Notifications',
				icon: BellIcon,
				href: '/app/management/notifications',
				items: [
					{
						title: 'List Notifications',
						href: '/app/management/notifications',
					},
					{
						title: 'Notification Template',
						href: '/app/management/notifications/template',
					},
				],
			},
			{
				title: 'Live Sessions',
				icon: VideoIcon,
				href: '/app/management/live-sessions',
				items: [
					{
						title: 'List Sessions',
						href: '/app/management/live-sessions/session',
					},
					{
						title: 'List Webinar',
						href: '/app/management/live-sessions/webinar',
					},
				],
			},
			{
				title: 'Clusters',
				icon: MapIcon,
				href: '/app/management/clusters',
				items: [
					{
						title: 'List Clusters',
						href: '/app/management/clusters',
					},
				],
			},
			{
				title: 'Playablo Data',
				icon: BookOpenIcon,
				href: '/app/management/playablodata',
				items: [
					{
						title: 'List Playablo Data',
						href: '/app/management/playablodata',
					},
				],
			},
			// {
			//   title: 'Products',
			//   icon: ShoppingCartIcon,
			//   href: '/app/management/products',
			//   items: [
			//     {
			//       title: 'List Products',
			//       href: '/app/management/products'
			//     },
			//     {
			//       title: 'Create Product',
			//       href: '/app/management/products/create'
			//     }
			//   ]
			// },
			// {
			//   title: 'Orders',
			//   icon: FolderIcon,
			//   href: '/app/management/orders',
			//   items: [
			//     {
			//       title: 'List Orders',
			//       href: '/app/management/orders'
			//     },
			//     {
			//       title: 'View Order',
			//       href: '/app/management/orders/1'
			//     }
			//   ]
			// },
			// {
			//   title: 'Invoices',
			//   icon: ReceiptIcon,
			//   href: '/app/management/invoices',
			//   items: [
			//     {
			//       title: 'List Invoices',
			//       href: '/app/management/invoices'
			//     },
			//     {
			//       title: 'View Invoice',
			//       href: '/app/management/invoices/1'
			//     }
			//   ]
			// }
		],
	},

	// {
	//   subheader: 'Applications',
	//   items: [
	//     {
	//       title: 'Projects Platform',
	//       href: '/app/projects',
	//       icon: BriefcaseIcon,
	//       items: [
	//         {
	//           title: 'Overview',
	//           href: '/app/projects/overview'
	//         },
	//         {
	//           title: 'Browse Projects',
	//           href: '/app/projects/browse'
	//         },
	//         {
	//           title: 'Create Project',
	//           href: '/app/projects/create'
	//         },
	//         {
	//           title: 'View Project',
	//           href: '/app/projects/1'
	//         }
	//       ]
	//     },
	//     {
	//       title: 'Social Platform',
	//       href: '/app/social',
	//       icon: ShareIcon,
	//       items: [
	//         {
	//           title: 'Profile',
	//           href: '/app/social/profile'
	//         },
	//         {
	//           title: 'Feed',
	//           href: '/app/social/feed'
	//         }
	//       ]
	//     },
	//     {
	//       title: 'Kanban',
	//       href: '/app/kanban',
	//       icon: TrelloIcon
	//     },
	//     {
	//       title: 'Mail',
	//       href: '/app/mail',
	//       icon: MailIcon
	//     },
	//     {
	//       title: 'Chat',
	//       href: '/app/chat',
	//       icon: MessageCircleIcon,
	//       info: () => (
	//         <Chip
	//           color="secondary"
	//           size="small"
	//           label="Updated"
	//         />
	//       )
	//     },
	//     {
	//       title: 'Calendar',
	//       href: '/app/calendar',
	//       icon: CalendarIcon,
	//       info: () => (
	//         <Chip
	//           color="secondary"
	//           size="small"
	//           label="Updated"
	//         />
	//       )
	//     }
	//   ]
	// },
	// {
	//   subheader: 'Auth',
	//   items: [
	//     {
	//       title: 'Login',
	//       href: '/login-unprotected',
	//       icon: LockIcon
	//     },
	//     {
	//       title: 'Register',
	//       href: '/register-unprotected',
	//       icon: UserPlusIcon
	//     }
	//   ]
	// },
	// {
	//   subheader: 'Pages',
	//   items: [
	//     {
	//       title: 'Account',
	//       href: '/app/account',
	//       icon: UserIcon
	//     },
	//     {
	//       title: 'Error',
	//       href: '/404',
	//       icon: AlertCircleIcon
	//     },
	//     {
	//       title: 'Pricing',
	//       href: '/pricing',
	//       icon: DollarSignIcon
	//     }
	//   ]
	// },
	// {
	//   subheader: 'Extra',
	//   items: [
	//     {
	//       title: 'Charts',
	//       href: '/app/extra/charts',
	//       icon: BarChartIcon,
	//       items: [
	//         {
	//           title: 'Apex Charts',
	//           href: '/app/extra/charts/apex'
	//         }
	//       ]
	//     },
	//     {
	//       title: 'Forms',
	//       href: '/app/extra/forms',
	//       icon: EditIcon,
	//       items: [
	//         {
	//           title: 'Formik',
	//           href: '/app/extra/forms/formik'
	//         },
	//         {
	//           title: 'Redux Forms',
	//           href: '/app/extra/forms/redux'
	//         },
	//       ]
	//     },
	//     {
	//       title: 'Editors',
	//       href: '/app/extra/editors',
	//       icon: LayoutIcon,
	//       items: [
	//         {
	//           title: 'DraftJS Editor',
	//           href: '/app/extra/editors/draft-js'
	//         },
	//         {
	//           title: 'Quill Editor',
	//           href: '/app/extra/editors/quill'
	//         }
	//       ]
	//     }
	//   ]
	// }
];
const dashboardAdminSection: Section[] = [
	{
		subheader: 'Reports',
		items: [
			{
				title: 'Dashboard',
				icon: PieChartIcon,
				href: '/app/reports/google-dashboard',
			},
		],
	},
];
const enablerSection: Section[] = [
	{
		subheader: 'Pipeline',
		items: [
			{
				title: 'Leads',
				icon: LayersIcon,
				href: '/app/pipeline/leads',
				items: [
					{
						title: 'All',
						href: '/app/pipeline/leads',
					},
				],
			},
			{
				title: 'Playablo Data',
				icon: BookOpenIcon,
				href: '/app/management/playablodata',
			},
		],
	},
];
const telecallerSection: Section[] = [
	{
		subheader: 'Pipeline',
		items: [
			{
				title: 'Leads',
				icon: LayersIcon,
				href: '/app/pipeline/leads',
				items: [
					{
						title: 'All',
						href: '/app/pipeline/leads',
					},
				],
			},
			{
				title: 'Bulk Upload Logs',
				items: [
					{
						title: 'Lead Logs',
						href: '/app/pipeline/leads/bulkUpload_logs',
					},
				],
			},

			{
				title: 'Playablo Data',
				icon: BookOpenIcon,
				href: '/app/management/playablodata',
			},
		],
	},
];
const reportSection: Section[] = [
	{
		subheader: 'management',
		items: [
			{
				title: 'Leads',
				icon: LayersIcon,
				href: '/app/management/reportadmin',
			},
		],
	},
];
const flipkartSection: Section[] = [
	{
		subheader: 'Pipeline',
		items: [
			{
				title: 'Leads',
				icon: LayersIcon,
				href: '/app/pipeline/leads',
				items: [
					{
						title: 'All',
						href: '/app/pipeline/leads',
					},
				],
			},
			{
				title: 'Bulk Upload Logs',
				items: [
					{
						title: 'Lead Logs',
						href: '/app/pipeline/leads/bulkUpload_logs',
					},
					{
						title: 'FKB2C Logs',
						href: '/app/pipeline/leads/FKB2C_Logs',
					},
				],
			},
		],
	},
];
const msmeUserSections: Section[] = [
	// {
	// subheader: 'Management',
	// items: [
	//   {
	//     title: 'Users',
	//     icon: UsersIcon,
	//     href: '/app/management/users',
	//     items: [
	//       {
	//         title: 'List Users',
	//         href: '/app/management/users'
	//       },
	//       {
	//         title: 'View Users',
	//         href: '/app/management/users/1'
	//       },
	//       {
	//         title: 'Edit Users',
	//         href: '/app/management/users/1/edit'
	//       }
	//     ]
	//   },
	// {
	//   title: 'Products',
	//   icon: ShoppingCartIcon,
	//   href: '/app/management/products',
	//   items: [
	//     {
	//       title: 'List Products',
	//       href: '/app/management/products'
	//     },
	//     {
	//       title: 'Create Product',
	//       href: '/app/management/products/create'
	//     }
	//   ]
	// },
	// {
	//   title: 'Orders',
	//   icon: FolderIcon,
	//   href: '/app/management/orders',
	//   items: [
	//     {
	//       title: 'List Orders',
	//       href: '/app/management/orders'
	//     },
	//     {
	//       title: 'View Order',
	//       href: '/app/management/orders/1'
	//     }
	//   ]
	// },
	// {
	//   title: 'Invoices',
	//   icon: ReceiptIcon,
	//   href: '/app/management/invoices',
	//   items: [
	//     {
	//       title: 'List Invoices',
	//       href: '/app/management/invoices'
	//     },
	//     {
	//       title: 'View Invoice',
	//       href: '/app/management/invoices/1'
	//     }
	//   ]
	// }
	//   ]
	// },
	// {
	//   subheader: 'Applications',
	//   items: [
	//     {
	//       title: 'Projects Platform',
	//       href: '/app/projects',
	//       icon: BriefcaseIcon,
	//       items: [
	//         {
	//           title: 'Overview',
	//           href: '/app/projects/overview'
	//         },
	//         {
	//           title: 'Browse Projects',
	//           href: '/app/projects/browse'
	//         },
	//         {
	//           title: 'Create Project',
	//           href: '/app/projects/create'
	//         },
	//         {
	//           title: 'View Project',
	//           href: '/app/projects/1'
	//         }
	//       ]
	//     },
	//     {
	//       title: 'Social Platform',
	//       href: '/app/social',
	//       icon: ShareIcon,
	//       items: [
	//         {
	//           title: 'Profile',
	//           href: '/app/social/profile'
	//         },
	//         {
	//           title: 'Feed',
	//           href: '/app/social/feed'
	//         }
	//       ]
	//     },
	//     {
	//       title: 'Kanban',
	//       href: '/app/kanban',
	//       icon: TrelloIcon
	//     },
	//     {
	//       title: 'Mail',
	//       href: '/app/mail',
	//       icon: MailIcon
	//     },
	//     {
	//       title: 'Chat',
	//       href: '/app/chat',
	//       icon: MessageCircleIcon,
	//       info: () => (
	//         <Chip
	//           color="secondary"
	//           size="small"
	//           label="Updated"
	//         />
	//       )
	//     },
	//     {
	//       title: 'Calendar',
	//       href: '/app/calendar',
	//       icon: CalendarIcon,
	//       info: () => (
	//         <Chip
	//           color="secondary"
	//           size="small"
	//           label="Updated"
	//         />
	//       )
	//     }
	//   ]
	// },
	// {
	//   subheader: 'Auth',
	//   items: [
	//     {
	//       title: 'Login',
	//       href: '/login-unprotected',
	//       icon: LockIcon
	//     },
	//     {
	//       title: 'Register',
	//       href: '/register-unprotected',
	//       icon: UserPlusIcon
	//     }
	//   ]
	// },
	// {
	//   subheader: 'Pages',
	//   items: [
	//     {
	//       title: 'Account',
	//       href: '/app/account',
	//       icon: UserIcon
	//     },
	//     {
	//       title: 'Error',
	//       href: '/404',
	//       icon: AlertCircleIcon
	//     },
	//     {
	//       title: 'Pricing',
	//       href: '/pricing',
	//       icon: DollarSignIcon
	//     }
	//   ]
	// },
	// {
	//   subheader: 'Extra',
	//   items: [
	//     {
	//       title: 'Charts',
	//       href: '/app/extra/charts',
	//       icon: BarChartIcon,
	//       items: [
	//         {
	//           title: 'Apex Charts',
	//           href: '/app/extra/charts/apex'
	//         }
	//       ]
	//     },
	//     {
	//       title: 'Forms',
	//       href: '/app/extra/forms',
	//       icon: EditIcon,
	//       items: [
	//         {
	//           title: 'Formik',
	//           href: '/app/extra/forms/formik'
	//         },
	//         {
	//           title: 'Redux Forms',
	//           href: '/app/extra/forms/redux'
	//         },
	//       ]
	//     },
	//     {
	//       title: 'Editors',
	//       href: '/app/extra/editors',
	//       icon: LayoutIcon,
	//       items: [
	//         {
	//           title: 'DraftJS Editor',
	//           href: '/app/extra/editors/draft-js'
	//         },
	//         {
	//           title: 'Quill Editor',
	//           href: '/app/extra/editors/quill'
	//         }
	//       ]
	//     }
	//   ]
	// }
	{
		subheader: 'Pages',
		items: [
			{
				title: 'Home',
				href: '/app/home',
				icon: HomeIcon,
			},
			// {
			//   title: 'Courses',
			//   href: '/404',
			//   icon: FolderIcon
			// },
			// {
			//   title: 'Survey',
			//   href: '/404',
			//   icon: FolderIcon
			// },
			// {
			//   title: 'Notifications',
			//   href: '/404',
			//   icon: FolderIcon
			// }
		],
	},
];
const mentorSection: Section[] = [
	{
		subheader: 'Pipeline',
		items: [
			{
				title: 'MSME Assesment Submissions',
				href: '/app/pipeline/msme-assesment-submissions',
				icon: StreetviewOutlined,
			},
			{
				title: 'Talk to MSME',
				href: '/app/pipeline/talk-to-msme',
				icon: PeopleOutline,
			},
			{
				title: 'My TimeLine',
				href: '/app/pipeline/my-timeline',
				icon: TimelineOutlined,
			},
			{
				title: 'My Mentees',
				href: '/app/pipeline/mentee-change-request',
				icon: PersonAdd,
			},
		],
	},
];

function renderNavItems({
	items,
	pathname,
	depth = 0,
}: {
	items: Item[];
	pathname: string;
	depth?: number;
}) {
	return (
		<List disablePadding>
			{items.reduce(
				(acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
				[],
			)}
		</List>
	);
}

function reduceChildRoutes({
	acc,
	pathname,
	item,
	depth,
}: {
	acc: any[];
	pathname: string;
	item: Item;
	depth: number;
}) {
	const key = item.title + depth;

	if (item.items) {
		const open = matchPath(pathname, {
			path: item.href,
			exact: false,
		});

		acc.push(
			<NavItem
				depth={depth}
				icon={item.icon}
				info={item.info}
				key={key}
				open={Boolean(open)}
				title={item.title}
			>
				{renderNavItems({
					depth: depth + 1,
					pathname,
					items: item.items,
				})}
			</NavItem>,
		);
	} else {
		acc.push(
			<NavItem
				depth={depth}
				href={item.href}
				icon={item.icon}
				info={item.info}
				key={key}
				title={item.title}
			/>,
		);
	}

	return acc;
}

const useStyles = makeStyles(() => ({
	mobileDrawer: {
		width: 256,
	},
	desktopDrawer: {
		width: 256,
		top: 64,
		height: 'calc(100% - 64px)',
	},
	avatar: {
		cursor: 'pointer',
		width: 64,
		height: 64,
	},
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
	const classes = useStyles();
	const location = useLocation();
	const { user } = useAuth();
	const [downloadReport, setDownloadReport] = useState<boolean>(false);
	const [sections, setSections] = useState<Section[]>(
		user?.role.type === 'admin'
			? adminSections
			: user?.role.type === 'cluster_manager'
			? cmSections
			: user?.role.type === 'enabler'
			? enablerSection
			: user?.role.type === 'mentor'
			? mentorSection
			: user?.role.type === 'telecaller'
			? telecallerSection
			: user?.role.type === 'fk_admin' || user?.role.type === 'operation_head'
			? reportSection
			: user?.role.type === 'dashboard_admin'
			? dashboardAdminSection
			: user?.role.type === 'flipkartb_2_b' ||
			  user?.role.type === 'flipkartb_2_c' ||
			  user?.role.type === 'flipkartb_2_badmin'
			? flipkartSection
			: msmeUserSections,
	);
	const [getUserClusters, { data: clusterManagers }] = useLazyQuery(
		GET_USER_CLUSTERS,
		{
			fetchPolicy: 'cache-and-network',
		},
	);

	const [getClusters, { data: clusters }] = useLazyQuery(GET_CLUSTERS, {
		fetchPolicy: 'cache-and-network',
	});

	useQuery(GET_PIPELINE_WITH_STAGES, {
		fetchPolicy: 'network-only',
		variables: {
			pipelineStageSort: 'weight:ASC',
			where: {
				users_permissions_role: user?.role?.id,
			},
		},
		skip: user.role.type !== 'enabler',
		onCompleted: data => {
			let updateSections = [...sections];
			let pipelineItems: any = [];
			pipelineItems.push({
				title: 'All',
				href: `/app/pipeline/leads/stage/all`,
			});
			data?.pipelines[0]?.pipeline_stages?.forEach(element => {
				pipelineItems.push({
					title: element?.stage || '-',
					href: `/app/pipeline/leads/stage/${element?.id}`,
				});
			});

			if (user.role.type === 'enabler')
				updateSections[0].items[0].items = pipelineItems;

			setSections(updateSections);
		},
	});

	useEffect(() => {
		if (user?.role?.type === 'admin') {
			let updatedSections = [...sections];
			updatedSections[1].items[0].items.push({
				title: 'Create User',
				href: '/app/management/user/create',
			});
			setSections(updatedSections);
		}
	}, []);

	useEffect(() => {
		if (user?.role?.type === 'admin') {
			getClusters();
		}
	}, [user, getClusters]);

	// Note : here user cluster mapping is named as cluster manager
	useEffect(() => {
		let forRoles = ['admin', 'telecaller', 'cluster_manager'];
		if (forRoles.includes(user?.role.type)) {
			let updateSections = [...sections];
			let clustersMenuItems: any = [
				{
					title: 'All',
					href: '/app/pipeline/leads',
				},
			];
			clusters?.clusters?.forEach(cluster => {
				if (cluster)
					clustersMenuItems.push({
						title: cluster?.name || '-',
						href: `/app/pipeline/leads/cluster/${cluster?.id}`,
					});
			});

			if (user.role.type === 'admin') {
				updateSections[0].items[0].items = clustersMenuItems;
			}
			setSections(updateSections);
		}
	}, [clusters, setSections]);
	// for admin ['admin','cluster_manager','flipkartb_2_c']
	useEffect(() => {
		let forRoles = ['admin', 'flipkartb_2_c', 'cluster_manager'];
		if (forRoles.includes(user?.role.type)) {
			let updateSections = [...sections];
			let clustersMenuItems: any = [
				{
					title: 'All',
					href: '/app/pipeline/leads',
				},
			];
			clusters?.clusters?.forEach(cluster => {
				if (cluster)
					clustersMenuItems.push({
						title: cluster?.name || '-',
						href: `/app/pipeline/leads/cluster/${cluster?.id}`,
					});
			});

			if (
				user.role.type === 'admin' ||
				user.role.type === 'cluster_manager' ||
				user.role.type === 'flipkartb_2_c'
			) {
				// clustersMenuItems.push({
				// 		title: 'Bulk Upload Logs',
				// 		items:[
				// 			{
				// 				title: 'Lead Logs',
				// 				href: '/app/pipeline/leads/bulkUpload_logs',
				// 			},
				// 			{
				// 				title: 'FKB2C Logs',
				// 				href: '/app/pipeline/leads/FKB2C_Logs',
				// 			}
				// 		]
				// 	}
				// );
				updateSections[0].items[0].items = clustersMenuItems;
			}
			setSections(updateSections);
		}
	}, [clusters, setSections]);

	useEffect(() => {
		if (user?.role?.type !== 'admin') {
			getUserClusters({
				variables: {
					where: {
						users_permissions_user: user.id,
					},
				},
			});
		}
	}, [user, getUserClusters]);

	// Note : here user cluster mapping is named as cluster manager
	useEffect(() => {
		let forRoles = ['admin', 'telecaller', 'cluster_manager'];
		if (forRoles.includes(user?.role.type)) {
			let updateSections = [...sections];
			let clustersMenuItems: any = [];
			clusterManagers?.clusterManagers.forEach(clusterManager => {
				if (clusterManager?.cluster)
					clustersMenuItems.push({
						title: clusterManager?.cluster?.name || '-',
						href: `/app/pipeline/leads/cluster/${clusterManager?.cluster?.id}`,
					});
			});

			if (user.role.type === 'admin' || user.role.type === 'cluster_manager') {
				// clustersMenuItems.push({
				// 	title: 'Bulk Upload Logs',
				// 	items:[
				// 		{
				// 			title: 'Lead Logs',
				// 			href: '/app/pipeline/leads/bulkUpload_logs',
				// 		},
				// 		{
				// 			title: 'FKB2C Logs',
				// 			href: '/app/pipeline/leads/FKB2C_Logs',
				// 		}
				// 	]
				// });
				updateSections[0].items[0].items = clustersMenuItems;
			}

			if (user.role.type === 'telecaller' || user.role.type === 'enabler')
				updateSections[0].items[0].items = clustersMenuItems;

			setSections(updateSections);
		}
	}, [clusterManagers, setSections]);

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	const content = (
		<Box height="100%" display="flex" flexDirection="column">
			<PerfectScrollbar options={{ suppressScrollX: true }}>
				<Hidden lgUp>
					<Box p={2} display="flex" justifyContent="center">
						<RouterLink to="/">
							<Logo />
						</RouterLink>
					</Box>
				</Hidden>
				<Box p={2}>
					<Box display="flex" justifyContent="center">
						<RouterLink to="/app/account">
							<Avatar
								alt={user?.user_profile?.name || ''}
								className={classes.avatar}
								src={user?.user_profile?.image?.url}
							/>
						</RouterLink>
					</Box>
					<Box mt={2} textAlign="center">
						<Link
							component={RouterLink}
							to="/app/account"
							variant="h5"
							color="textPrimary"
							underline="none"
						>
							{user?.user_profile?.name}
						</Link>
						{user?.role?.type === 'authenticated' && (
							<>
								<Typography variant="body2" color="textSecondary">
									Business Entity:{' '}
									<Link component={RouterLink} to="/app/account">
										{user?.user_profile?.business_entity?.name || '-'}
									</Link>
								</Typography>
								<Typography variant="body2" color="textSecondary">
									Primary Place:{' '}
									<Link component={RouterLink} to="/app/account">
										{user?.user_profile?.business_entity?.primary_place || '-'}
									</Link>
								</Typography>
								<Typography variant="body2" color="textSecondary">
									Business Type:{' '}
									<Link component={RouterLink} to="/app/account">
										{user?.user_profile?.business_entity?.business_type?.name ||
											'-'}
									</Link>
								</Typography>
							</>
						)}
					</Box>
				</Box>
				<Divider />
				<Box p={2}>
					{user?.role?.type === 'cluster_manager' && (
						<List>
							<Link component={RouterLink} to="/app/tracker">
								Tracker
							</Link>
						</List>
					)}

					{sections.map(section => (
						<List
							key={section.subheader}
							subheader={
								<ListSubheader disableGutters disableSticky>
									{section.subheader}
								</ListSubheader>
							}
						>
							{renderNavItems({
								items: section.items,
								pathname: location.pathname,
							})}
						</List>
					))}
				</Box>
				<Divider />
				<Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
					<Typography variant="h6" color="textPrimary">
						Need Help?
					</Typography>
					{/* <Link
						variant="subtitle1"
						color="secondary"
						component={RouterLink}
						to="/docs"
					>
						Check our docs
					</Link> */}
				</Box>
			</PerfectScrollbar>
		</Box>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					classes={{ paper: classes.mobileDrawer }}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer
					anchor="left"
					classes={{ paper: classes.desktopDrawer }}
					open
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
};

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

export default NavBar;
