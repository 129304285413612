import React, { useRef, useState, useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
	Avatar,
	Box,
	Button,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	Popover,
	SvgIcon,
	Tooltip,
	Typography,
	makeStyles,
} from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import { Bell as BellIcon, MessageCircle as MessageIcon } from 'react-feather';
import type { Theme } from 'src/theme';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_USER_NOTIFICATIONS } from 'src/graphql/user_notifications/query';
import useAuth from 'src/hooks/useAuth';
import LoadingScreen from 'src/components/LoadingScreen';
import NotificationDialog from './notificationDialog';
import { UPDATE_USER_NOTIFICATION } from 'src/graphql/user_notifications/mutations';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

const useStyles = makeStyles((theme: Theme) => ({
	popover: {
		width: 320,
	},
	icon: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText,
	},
	date: {
		marginLeft: 'auto',
		flexShrink: 0,
	},
	verifiedIcon :{
		fontSize : '20px',
		color :theme.palette.success.main
	  },
}));

const Notifications: FC = () => {
	const classes = useStyles();
	// const { notifications } = useSelector((state) => state.notifications);
	const ref = useRef<any>(null);
	const [isOpen, setOpen] = useState<boolean>(false);
	const [isFormOpen, setFormOpen] = useState(null);
	const { user } = useAuth() as any;
	const { enqueueSnackbar } = useSnackbar();

	const [getUserNotifications, { data, loading }] = useLazyQuery(
		GET_USER_NOTIFICATIONS,
		{
			fetchPolicy: 'cache-and-network',
			pollInterval: 4000,
		},
	);

	const [updateUserNotification] = useMutation(UPDATE_USER_NOTIFICATION);

	useEffect(() => {
		if (user) {
			/*getUserNotifications({
				variables: {
					where: {
						users_permissions_user: user.id,
						'notification.type': 'GENERAL',
					},
					sort: 'created_at:DESC',
				},
			});
			*/
		}
	}, [user, getUserNotifications]);

	let notifications = data?.userNotifications || [];
	const handleOpen = (): void => {
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
	};

	const handleFormOpen = (notification: any): void => {
		setFormOpen(notification);
	};

	const handleFormClose = (): void => {
		setFormOpen(false);
	};

	const handleInterested = async (
		userNotificationId: string,
		interested: boolean,
	) => {
		try {
			await updateUserNotification({
				variables: {
					input: {
						where: {
							id: userNotificationId,
						},
						data: {
							interested: interested,
						},
					},
				},
			});
			handleClose();
			enqueueSnackbar('Feedback Added', {
				variant: 'success',
			});
		} catch (err) {
			enqueueSnackbar(err?.response?.data?.message || 'Something went wrong', {
				variant: 'error',
			});
		}
	};

	return (
		<>
			<Tooltip title="Notifications">
				<IconButton color="inherit" ref={ref} onClick={handleOpen}>
					<SvgIcon>
						<BellIcon />
					</SvgIcon>
				</IconButton>
			</Tooltip>
			<Popover
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				classes={{ paper: classes.popover }}
				anchorEl={ref.current}
				onClose={handleClose}
				open={isOpen}
			>
				<Box p={2}>
					<Typography variant="h5" color="textPrimary">
						Notifications
					</Typography>
				</Box>
				{notifications.length === 4 ? (
					<Box p={2}>
						<Typography variant="h6" color="textPrimary">
							There are no notifications
						</Typography>
					</Box>
				) : (
					<>
						{loading && <LoadingScreen />}

						<List disablePadding>
							{/* {[1,2,3,4].map(item => {
								return <ListItem style={{justifyContent:"space-around"}}>
									<Button>
										<CallIcon/>
									</Button>
									<Typography style={{margin: "0"}}>
									<h6>MSME NAME</h6>
									<h5>User Name</h5>
									<h6>21/08/2021 09:23PM</h6>
									</Typography>
 
								</ListItem>
							})} */}
							{/* {notifications.map(notification => {
								const { type } = notification?.notification;
								function truncate(str, n) {
									return str.length > n ? str.substr(0, n - 1) + ' ...' : str;
								}
								if (!notification?.notification?.is_publish) return undefined;
								return (
									<ListItem
										divider
										key={notification.id}
										onClick={() => {
											handleFormOpen(notification);
										}}
									>
										<ListItemAvatar>
											<Avatar
												className={classes.icon}
												src={notification?.notification?.icon?.url}
											>
												<SvgIcon fontSize="small">
													<MessageIcon />
												</SvgIcon>
											</Avatar>
										</ListItemAvatar>
										<ListItem>
											<Box width="100%">
												<Typography variant="subtitle1" color={'textPrimary'}>
													{truncate(
														notification?.notification?.title || '',
														45,
													)}
												</Typography>
												<Typography color="textSecondary" variant="body2">
													{truncate(
														notification?.notification?.description || '',
														45,
													)}
												</Typography>
												{type === 'INTEREST' &&
													notification?.interested === null && (
														<Box mt={1}>
															<Button
																size="small"
																color="primary"
																onClick={() =>
																	handleInterested(notification.id, true)
																}
															>
																I am Interested ?
															</Button>
														</Box>
													)}
												{notification?.interested === true && (
													<Box mt={1} display="flex">
														<Typography variant="body2" color="textSecondary">
															Interested
														</Typography>
														<Box ml={1}>
															<CheckCircleRoundedIcon
																className={classes.verifiedIcon}
															/>
														</Box>
													</Box>
												)}
												<Box display="flex" p={1}>
													<Box flexGrow={1} />
													<Typography
														className={classes.date}
														variant="caption"
													>
														{notification?.notification?.published_at
															? moment(
																	notification?.notification?.published_at,
															  ).fromNow()
															: '-'}
													</Typography>
												</Box>
											</Box>
										</ListItem>
									</ListItem>
								);
							})} */}
						</List>

						<Box p={1} display="flex" justifyContent="center">
							<Button component={RouterLink} size="small" to="#">
								Mark all as read
							</Button>
						</Box>
					</>
				)}
			</Popover>
			{isFormOpen && (
				<NotificationDialog
					isOpen={isFormOpen}
					notification={isFormOpen}
					handleClose={handleFormClose}
				/>
			)}
		</>
	);
};

export default Notifications;
